<template>
    <div class="payouts-status-container">
        <div
            v-if="sw_status===''"
            class="payouts-status"
            :class="getClass"
        >
            {{ status }}
        </div>
        <div
            v-if="sw_status"
            class="payouts-status"
            :class="getClassReceived"
        >
            {{ sw_status }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'PayoutsStatus',
    props: {
        status: {
            type: String,
            required: true
        },
        sw_status: {
            type: String,
            required: true
        }
    },
    computed: {
        getClass() {
            return `payouts-status--${this.status.toLowerCase()}`
        },
        getClassReceived() {
            return `payouts-status--${this.sw_status.toLowerCase()}`
        }
    }
}
</script>

<style scoped lang="scss">

.payouts-status {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 15px;
    width: 70%;
    text-align: center;
    &--request {
        background: rgba(17, 97, 224, 0.1);
        color: #1161E0;
    }
    &--received {
        background: rgba(255, 105, 12, 0.1);
        color: #FF690C;
    }
    &--processed {
        background: rgba(26, 175, 41, 0.1);
        color:#1AAF29;
    }
    @media all and (max-width: 480px) {
        width: 50%;
    }
}

.payouts-status-container{
    @media all and (max-width: 480px) {
        margin: 20px 0;
    }
}

</style>
