<template>
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo">
                <header-content-addition :addition="headerInfo" />
            </header-content>
            <loading-overlay v-if="dataLoading" />
            <div
                v-if="payouts.length > 0"
                class="wrapper"
            >
                <payouts-table
                    :headings="payoutsHeading"
                    :payouts="payouts"
                    @deleteRequest="openConfirmModal('Delete request', 'Are you really want to delete the request ?', $event)"
                />
                <div class="payouts-button-center">
                    <custom-button
                        v-if="lastPage > 1"
                        default
                        class="button btn-base_colored sort-button"
                        @on-btn-click="loadMore"
                    >
                        Load more
                    </custom-button>
                </div>
            </div>
            <!--            <NoResult-->
            <!--                v-if="!payouts"-->
            <!--                type="credits"-->
            <!--            />-->
            <transition name="fade">
                <div
                    v-if="!payouts.length"
                    class="payouts-not-found"
                >
                    <not-found
                        text="You do not have any payouts yet."
                        desc="Go to the earnings section and check your balance."
                    />
                </div>
            </transition>
            <confirm-modal ref="confirm-modal" />
        </template>
    </main-layout>
</template>

<script>

// import payoutsTest from './payoutsTest'
import MainLayout from '@/layouts/MainLayout';
// import NoResult from '@/components/common/NoResult'
import HeaderContent from '@/components/HeaderContent';
import HeaderContentAddition from '@/components/HeaderContentAddition';
import PayoutsTable from '@/views/account/payouts/PayoutsTable.vue'
import LoadingOverlay from '@/components/LoadingOverlay';
import NotFound from '@/components/NotFound'
import ConfirmModal from '@/components/modals/ConfirmModal'

import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus';

export default {
    metaInfo: {
        title: 'Payouts'
    },
    components: {
        MainLayout,
        // NoResult,
        NotFound,
        HeaderContent,
        HeaderContentAddition,
        PayoutsTable,
        LoadingOverlay,
        ConfirmModal
    },
    mixins: [
    ],
    data() {
        return {
            payouts: [],
            rowsPerPage: 10,
            page: 1,
            lastPage: null,
            balance: '',
            dataLoading: false,
            payoutsHeading: ['Payment Id', 'Transaction date', 'Orders', 'Status', 'Payment method', 'Total'],
            headerInfo: {
                title: 'Payouts',
                className: 'header-content__credits',
                report: true,
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    }
                ]
            }
        }
    },
    async created() {
        await this.getData()
    },
    methods: {
        async getData() {
            this.dataLoading = true;
            const vm = this
            await Api.get('/api/payout/list', { page: this.page })
                .then((response) => {
                    this.payouts = [...this.payouts, ...response.data.data]
                    this.lastPage = response.data
                })
                .then(() => {
                    vm.dataLoading = false;
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        loadMore() {
            this.page += 1
            this.getData()
        },
        async deleteRequest(id) {
            try {
                await Api.delete('/api/payout/delete', { paymentid: id })
                this.payouts = this.payouts.filter(({ paymentid }) => paymentid !== id)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        openConfirmModal(title, message, id) {
            this.$refs['confirm-modal'].open(title, message)
                .then((res) => {
                    if (res) {
                        this.deleteRequest(id)
                    }
                })
        }
    }
}
</script>

<style lang="scss">

.payouts-button-center {
    display: flex;
    justify-content: center;
}

.payouts-not-found{
    margin: 100px 0;
}

</style>
