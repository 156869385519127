<template>
    <div class="payouts-table">
        <div class="payouts-table__heading">
            <div
                v-for="(heading,index) in headings"
                :key="index"
                class="payouts-table__table-col"
            >
                {{ heading }}
            </div>
        </div>

        <div class="payouts-table__row-wrapper">
            <div
                v-for="(payout,index) in payouts"
                :key="index"
                class="payouts-table__row"
            >
                <div
                    class="payouts-table__row-inner"
                    @click="isShowOrders(index)"
                >
                    <div class="payouts-table__row-id">
                        <span class="payouts-table__row-item payouts-table__row-index">{{ index + 1 }}.</span>
                        <img
                            src="@/assets/img/svg/order-id.svg"
                            alt="Order id"
                            width="20px"
                            height="20px"
                            class="card_body__detail-img"
                            style="margin-right: 10px"
                        >
                        <span>{{ getRowData(payout.paymentid) }}</span>
                    </div>

                    <div class="payouts-table__row-item payouts-table__row-create-data">
                        <img
                            src="@/assets/img/svg/arrow-double-gray.svg"
                            alt="Due"
                            width="16px"
                            height="16px"
                            class="card_body__detail-img"
                            style="margin-right: 10px"
                        >
                        <span style="margin-bottom: 2px">{{ payout.created_at | moment_from }} ago</span>
                    </div>

                    <div class="payouts-table__row-item payouts-table__row-orders">
                        {{ payout.order_details.length }}
                    </div>

                    <payouts-status
                        :status="payout.status"
                        :sw_status="payout.sw_status"
                        class="payouts-table__row-item payouts-table__row-status"
                    />

                    <div class="payouts-table__row-item payouts-table__row-payment-method">
                        <span>{{ getRowData(payout.pay_method) }}</span>
                    </div>

                    <div class="payouts-table__row-item payouts-table__row-total">
                        ${{ payout.amount }}
                    </div>

                    <div
                        v-if="payout.status === 'REQUEST'"
                        class="payouts-table__trash"
                        @click.stop="deleteRequest(payout)"
                    >
                        <trash-can-outline />
                    </div>

                    <div
                        v-if="payout.status !== 'REQUEST'"
                        class="card_header-togle card_header-togle--fullscreen payouts-table__chevron"
                    >
                        <span v-if="isShow === index">
                            <chevron-up-icon />
                        </span>
                        <span v-else>
                            <chevron-down-icon />
                        </span>
                    </div>
                </div>
                <div
                    v-if="payout.status !== 'REQUEST'"
                    class="payouts-table__orders"
                >
                    <payouts-orders
                        v-if="isShow === index && payout.order_details.length > 0"
                        :orders="payout.order_details"
                    />
                    <div
                        class="card_header-togle card_header-togle--mobile"
                        @click="isShowOrders(index)"
                    >
                        <span v-if="isShow === index">
                            Hide details
                            <chevron-up-icon />
                        </span>
                        <span v-else>
                            Show details
                            <chevron-down-icon />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PayoutsStatus from '@/views/account/payouts/PayoutsStatus.vue'
import PayoutsOrders from '@/views/account/payouts/PayoutsOrders.vue'
import ChevronUpIcon from 'mdi-vue/ChevronUp'
import TrashCanOutline from 'mdi-vue/TrashCanOutline'
import ChevronDownIcon from 'mdi-vue/ChevronDown'

import filtersMixin from '@/mixins/filtersMixin.js';

export default {
    name: 'PayoutsTable',
    components: {
        PayoutsStatus,
        PayoutsOrders,
        ChevronUpIcon,
        ChevronDownIcon,
        TrashCanOutline
    },
    mixins: [filtersMixin],
    props: {
        headings: {
            type: Array,
            required: true
        },
        payouts: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isShow: false,
            currentIndex: null
        }
    },
    methods: {
        getRowData(rowData) {
            return rowData || '- - - - - - - - - - - - -'
        },
        isShowOrders(index) {
            this.isShow === index
                ? this.isShow = false
                : this.isShow = index
        },
        deleteRequest({ paymentid }) {
            this.$emit('deleteRequest', paymentid)
        }
    }
}
</script>

<style lang="scss" scoped>
.payouts-table {
    margin: 30px 0 50px;
    &__heading {
        display: flex;
        line-height: 20px;
        padding: 0px 90px 20px 70px;
    }
    &__table-col {
        line-height: 20px;
        color: #afb2b9;
        font-size: 14px;
        font-weight: 400;
        &:nth-child(1){
            width: 20%;
            order: 1
        }
        &:nth-child(2){
            width: 19%;
            order: 2
        }
        &:nth-child(3){
            width: 5%;
            order: 5
        }
        &:nth-child(4){
            width: 19%;
            order: 3
        }
        &:nth-child(5){
            width: 20%;
            order: 4
        }
        &:nth-child(6){
            width: 17%;
            text-align: right;
            order: 6
        }
    }
    &__row--wrapper {
        display: flex;
        flex-direction: column;
    }
    &__row {
        box-shadow: 0px 2px 4px rgb(0 0 0 /20%);
        margin-bottom: 15px;
        transition: 0.5s;
        &:hover{
            box-shadow: 0 5px 30px rgb(0 0 0 / 20%);
        }
    }
    &__row-inner {
        padding: 25px 90px 25px 40px  ;
        display: flex;
        position: relative;
        font-size: 14px;
        align-items: center;
        cursor: pointer;
    }
    &__row-id{
        flex-basis: 25%;
        display: flex;
        align-items: center;
        white-space: nowrap;
        order: 1
    }
    &__row-index {
        margin-right: 20px;
        font-size: 14px;
        color: #afb2b9;
    }
    &__row-orders{
        flex-basis: 5%;
        order: 5;
        text-align: center;
    }
    &__row-create-data {
        flex-basis: 20%;
        white-space: nowrap;
        order: 2;
        display: flex;
        align-items: center;
    }
    &__row-payment-method{
        flex-basis: 20%;
        order: 4;
    }
    &__row-payment-method span{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__row-status{
        flex-basis: 20%;
        order: 3
    }
    &__row-total{
        text-align: right;
        flex-basis: 20%;
        color: #181818;
        font-weight: 500;
        font-size: 20px;
        order: 6
    }
    // &__chevron {
    //     right: 0;
    // }
    &__trash {
        position: absolute;
        right: 33px;
        top: 44px;
        height: 30px;
        transform: translateY(-50%);
        line-height: 30px;
        cursor: pointer;
        color: $error-color;
    }
}

@media all and (max-width: 1150px) {
    .payouts-table{
        &__heading{
            display: none;
        }
        &__row-inner{
            flex-wrap: wrap;
        }
        &__row-item{
            position: relative;
            line-height: 70px;
        }
        &__row-id{
            flex-basis: 33%;
            &::before {
                content: 'Payment Id';
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                top: 30px;
                left: 70px;
            }
        }
        &__row-create-data{
            flex-basis: 33%;
            &::before {
                content: 'Transaction date';
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                top: -25px;
            }
        }
        &__row-orders{
            flex-basis: 33%;
            text-align: left;
            &::before {
                content: 'Orders';
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                top: -20px;
            }
        }
        &__row-status{
            flex-basis: 33%;
            &::before {
                content: 'Status';
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                bottom: 15px;
            }
        }
        &__row-payment-method{
            flex-basis: 33%;
            &::before {
                content: 'Payment Method';
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                top: -20px;
            }
        }
        &__row-total{
            flex-basis: 33%;
            &::before {
                content: 'Total';
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                top: -20px;
            }
        }
    }
}

@media all and (max-width: 992px) {
    .payouts-table {
        &__orders {
            padding-bottom: 20px;
        }
        &__row {
            margin-bottom: 25px;
        }
        &__row-inner {
            padding: 10px 10px 0 15px;
        }
        &__row-id{
            &::before {
                top: 18px;
                left: 45px;
            }
        }
    }
}

@media all and (max-width: 768px){
    .payouts-table{
        &__row-id{
            flex-basis: 50%;
        }
        &__row-create-data{
            flex-basis: 50%;
        }
        &__row-orders{
            flex-basis: 10%;
            text-align: left;
        }
        &__row-status{
            flex-basis: 40%;
        }
        &__row-payment-method{
            flex-basis: 50%;
        }
        &__row-total{
            flex-basis: 100%;
            text-align: left;
        }
    }
}
//
@media all and (max-width: 480px){
    .payouts-table{
        &__row-id{
            flex-basis: 100%;
        }
        &__row-create-data{
            flex-basis: 70%;
        }
        &__row-orders{
            flex-basis: 30%;
            text-align: left;
        }
        &__row-status{
            flex-basis: 100%;
        }
        &__row-payment-method{
            flex-basis: 100%;
        }
        &__row-total{
            flex-basis: 100%;
            text-align: left;
        }
    }
}

</style>
