<template>
    <div class="payouts-orders__wrapper">
        <div
            v-for="(order,index ) in orders"
            :key="index"
            class="payouts-orders"
        >
            <div class="payouts-orders__orderid">
                <span class="payouts-orders__orderid-index">{{ index+1 }}.</span>
                <span
                    class="payouts-orders__orderid-id"
                    @click="redirectToTheId(order.orderid)"
                >
                    {{ order.orderid }}
                </span>
            </div>
            <span class="payouts-orders__cost">{{ order.cost | money }}</span>
        </div>
    </div>
</template>

<script>
// Mixins
import filtersMixin from '@/mixins/filtersMixin.js'

export default {
    name: 'PayoutsOrders',
    mixins: [
        filtersMixin
    ],
    props: {
        orders: {
            type: Array,
            required: true
        }
    },
    methods: {
        redirectToTheId(id) {
            this.$router.push({
                name: 'details',
                params: { id }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.payouts-orders {
    display: flex;
    line-height: 30px;
    align-items: center;
    &__wrapper {
        padding: 10px 90px 10px 40px;
        border-top: 1px solid #E7EFEF;
    }
    &__orderid {
        font-size: 14px;
        width: 50%;
        white-space: nowrap
    }
    &__orderid-index{
        color: #afb2b9;
        margin-right: 15px;
    }
    &__orderid-id{
        cursor: pointer;
        &:hover {
            color: grey;
        }
    }
    &__cost {
        color: #181818;
        font-size: 14px;
        font-weight: 500;
        width: 50%;
        text-align: right;
    }
}

//@media all and (max-width: 1150px) {
//
//}
//
@media all and (max-width: 992px) {
    .payouts-orders__wrapper {
        padding: 20px 15px;
    }
}

//@media all and (max-width: 768px){
//
//}

//@media all and (max-width: 596px){
//
//}

</style>
